import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@wdx/shared/infrastructure/state';
import { ArticlesState, adapter } from './articles.reducer';

const { selectEntities, selectAll, selectIds } = adapter.getSelectors();

export const selectState = createFeatureSelector<ArticlesState>('articles');

/**
 * Listing
 */

export const selectArticlesListingState = createSelector(
    selectState,
    (state) => state.listing
);

export const selectAllArticleListings = createSelector(
    selectArticlesListingState,
    selectAll
);

export const selectArticleListingIsLoading = createSelector(
    selectArticlesListingState,
    (state) => state.loading
);

export const selectArticleListingIsLoaded = createSelector(
    selectArticlesListingState,
    (state) => state.loaded
);

/**
 * Article
 */

export const selectArticlesState = createSelector(
    selectState,
    (state) => state.article
);

export const selectCurrentArticleId = createSelector(
    selectRouteParams,
    (state) => state?.['articleId']
);

export const selectLoadedArticleIds = createSelector(
    selectArticlesState,
    selectIds
);

export const selectAllArticles = createSelector(
    selectArticlesState,
    selectEntities
);

export const selectArticleIsLoading = createSelector(
    selectArticlesState,
    (state) => state?.loading
);

export const selectCurrentArticle = createSelector(
    selectAllArticles,
    selectCurrentArticleId,
    (articles, articleId) => articleId && articles[articleId]
);
