import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TeamListMember } from '@wdx/portal/api-models';
import {
    ERROR_STATUS,
    INITIAL_STATUS,
    LOADING_STATUS,
    SUCCESS_STATUS,
} from '../state.constants';
import { EntityResultsState } from '../state.model';
import { TeamActions } from './team.actions';

export interface TeamState {
    listing: EntityResultsState<TeamListMember>;
}

export const adapter: EntityAdapter<TeamListMember> =
    createEntityAdapter<TeamListMember>({
        selectId: (teamListMember) => teamListMember?.member?.id as string,
    });

const { selectAll } = adapter.getSelectors();

export const selectAllTeam = selectAll;

export const initialState = {
    listing: adapter.getInitialState({
        ...INITIAL_STATUS,
    }),
};

export const teamReducer = createReducer(
    initialState,

    on(TeamActions.getListing, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            ...LOADING_STATUS,
            loading: !state.listing.loaded,
        },
    })),

    on(TeamActions.getListingFromAPI, (state, props) => ({
        ...state,
        listing: adapter.addMany(props.response, {
            ...state.listing,
            ...SUCCESS_STATUS,
        }),
    })),

    on(TeamActions.getListingError, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            ...ERROR_STATUS,
        },
    }))
);
