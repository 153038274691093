import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Article } from '@wdx/portal/api-models';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { ArticleApiService } from '../../libs/api-contract';
import { ArticlesActions } from './articles.actions';
import {
    selectArticleListingIsLoaded,
    selectLoadedArticleIds,
} from './articles.selectors';

@Injectable()
export class ArticlesEffects {
    getListing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ArticlesActions.getListing),
            withLatestFrom(
                this.store$.pipe(select(selectArticleListingIsLoaded)),
            ),
            switchMap(([, loaded]) => {
                if (loaded) {
                    return of(ArticlesActions.getListingFromStore());
                }
                return this.articlesApiService.getArticles().pipe(
                    map((response) =>
                        ArticlesActions.getListingFromAPI({
                            response: response.results as Article[],
                        }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        ),
    );

    getListingFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(ArticlesActions.getListingFromStore)),
        { dispatch: false },
    );

    getArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ArticlesActions.getArticle),
            withLatestFrom(this.store$.pipe(select(selectLoadedArticleIds))),

            switchMap(([action, loadedArticleIds]) => {
                if ((loadedArticleIds as string[]).includes(action.articleId)) {
                    return of(ArticlesActions.getArticleFromStore());
                }
                return this.articlesApiService
                    .getArticle(action.articleId)
                    .pipe(
                        map((response) =>
                            ArticlesActions.getArticleFromAPI({
                                articleId: action.articleId,
                                response: response,
                            }),
                        ),
                        catchError(() => EMPTY),
                    );
            }),
        ),
    );

    getArticleFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(ArticlesActions.getArticleFromStore)),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private articlesApiService: ArticleApiService,
    ) {}
}
