import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxToastComponent } from '@wdx/shared/components/wdx-toast';
import { CustomRouteSerializer } from '@wdx/shared/infrastructure/state';
import {
    IGetConfig,
    IThemeService,
    TitleService,
    WINDOW_PROVIDERS,
} from '@wdx/shared/utils';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG } from './app.config';
import { ClientHttpInterceptor } from './core/interceptors/client.interceptor';
import { ChartsService } from './core/services/charts/charts.service';
import { ConfigService } from './core/services/config/config.service';
import { ApiConfigService } from './libs/api-contract/api-config.service';
import { ThemeService } from './libs/system/theme';
import { ApiErrorInterceptor } from './shared/interceptors/api-error.interceptor';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { effects, reducers } from './state';
import { ExternalTasksModule } from './state/external-tasks/external-tasks.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AuthModule.forRoot(),
        StoreModule.forRoot(reducers),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouteSerializer,
        }),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 500,
            logOnly: environment.production,
            connectInZone: true,
        }),
        AppRoutingModule,
        WdxSpinnerModule,
        PushPipe,
        ExternalTasksModule,
        WdxToastComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => () =>
                configService.initialize(),
            deps: [ConfigService, ApiConfigService, APP_CONFIG],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (chartsService: ChartsService) => () =>
                chartsService.initialize(),
            deps: [ChartsService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (titleService: TitleService) => () =>
                titleService.initialize('Client Portal'),
            deps: [TitleService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiErrorInterceptor,
            multi: true,
        },
        {
            provide: IGetConfig,
            useFactory: (config: ConfigService) => ({
                getConfiguration: () => ({
                    API_BASE: config.config.API_BASE,
                }),
            }),
            deps: [ConfigService],
        },
        ThemeService,
        AuthHttpInterceptor,
        {
            provide: IThemeService,
            useExisting: ThemeService,
        },
        provideHttpClient(withInterceptorsFromDi()),
        WINDOW_PROVIDERS,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
