import { createEntityAdapter, EntityAdapter, IdSelector } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DocumentParamKey, DocumentQueryResult } from '../../libs/api-contract';
import {
    ERROR_STATUS,
    INITIAL_STATUS,
    LOADING_STATUS,
    SUCCESS_STATUS,
} from '../state.constants';
import { PaginatedState } from '../state.model';
import { DocumentsActions } from './documents.actions';

export interface DocumentListingState
    extends PaginatedState<DocumentQueryResult> {
    paramKey: DocumentParamKey;
    unreadCount: number;
}

export interface DocumentsState {
    listing: DocumentListingState;
}

export const adapter: EntityAdapter<DocumentQueryResult> =
    createEntityAdapter<DocumentQueryResult>({
        selectId: ((documentQueryResult) =>
            documentQueryResult.paging
                ?.page) as IdSelector<DocumentQueryResult>,
    });

export const initialState = {
    listing: adapter.getInitialState({
        ...INITIAL_STATUS,
        paramKey: 'unreadonly' as DocumentParamKey,
        unreadCount: null,
    }),
};

export const documentsReducer = createReducer(
    initialState,

    on(DocumentsActions.getListing, (state, props) => ({
        ...state,
        listing: {
            ...state.listing,
            ...LOADING_STATUS,
            loading: !(state.listing.ids as number[]).includes(
                props.pageNumber
            ),
        },
    })),

    on(DocumentsActions.getListingFromAPI, (state, props) => ({
        ...state,
        listing: adapter.addOne(props.response, {
            ...state.listing,
            ...SUCCESS_STATUS,
            ...((props.paramKey === 'unreadonly' && {
                unreadCount: props.response.paging?.totalRecords,
            }) as any),
        }),
    })),

    on(DocumentsActions.getListingError, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            ...ERROR_STATUS,
        },
    })),

    on(DocumentsActions.setParamKey, (state, props) => ({
        ...state,
        listing: adapter.removeAll({
            ...state.listing,
            ...INITIAL_STATUS,
            paramKey: props.paramKey,
        }),
    }))
);
