import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { AppContentComponent } from '../../../shared/components/app-content/app-content.component';
import { PortalTenantService } from '../../../core/services/tenants/tenants.service';

@Component({
    selector: 'cp-cookie-policy-modal',
    standalone: true,
    imports: [
        CommonModule,
        WdxIconModule,
        WdxIconButtonModule,
        AppContentComponent,
    ],
    templateUrl: './cookie-policy-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyModalComponent implements OnInit {
    private activeModal = inject(NgbActiveModal);
    private http = inject(HttpClient);
    private sanitizer = inject(DomSanitizer);
    private cdr = inject(ChangeDetectorRef);
    private portalTenantService = inject(PortalTenantService);

    externalHtmlContent: SafeHtml;

    ngOnInit() {
        this.loadExternalContent();
    }

    loadExternalContent() {
        const url = `https://static.clmi-dev.io/portal/cookingpage/${this.portalTenantService?.tenantCode}.html`;
        this.http.get(url, { responseType: 'text' }).subscribe((data) => {
            this.externalHtmlContent =
                this.sanitizer.bypassSecurityTrustHtml(data);
            this.cdr.detectChanges();
        });
    }

    onClose() {
        this.activeModal.close();
    }
}
