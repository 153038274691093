<div
    class="welcome-container d-flex flex-column align-items-center justify-content-center"
>
    <img
        *ngIf="faviconService.faviconUrl"
        class="mb-5"
        [src]="faviconService.faviconUrl"
        alt=""
    />
    <h2 class="mb-4">You currently have no active clients relationships</h2>

    <p class="fs-5 mb-5">Please contact the support team</p>

    <button class="me-2 btn btn-primary" (click)="logout()">Log out</button>
</div>
