import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { PushPipe } from '@ngrx/component';
import { WdxAppContentModule } from '@wdx/shared/components/wdx-app-content';
import { WdxAppHeaderModule } from '@wdx/shared/components/wdx-app-header';
import { WdxAppShellModule } from '@wdx/shared/components/wdx-app-shell';
import { WdxAppSidebarModule } from '@wdx/shared/components/wdx-app-sidebar';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxNavModule } from '@wdx/shared/components/wdx-nav';
import { SitemapApiService } from '../../../libs/api-contract/sitemap/sitemap.service';
import { LocalStorageService } from '../../../libs/utils/services/local-storage.service';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ClientLogoComponent } from './client-logo/client-logo.component';
import { ClientSwitcherComponent } from './client-switcher/client-switcher.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CookiePolicyComponent } from '../../../features/cookie-policy/cookie-policy.component';

@Component({
    selector: 'cp-app-shell',
    templateUrl: './app-shell.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        WdxIconModule,
        NavigationComponent,
        ClientSwitcherComponent,
        ClientLogoComponent,
        UserMenuComponent,
        BreadcrumbsComponent,
        WdxAppShellModule,
        WdxAppContentModule,
        WdxNavModule,
        WdxAppSidebarModule,
        WdxAppHeaderModule,
        PushPipe,
        CookiePolicyComponent,
    ],
})
export class AppShellComponent implements OnInit {
    readonly COLLAPSED_STORAGE_KEY = 'GlobalNavigationIsCollapsed';

    collapsed!: boolean;

    private offcanvasService = inject(NgbOffcanvas);
    private localstorageService = inject(LocalStorageService);
    private sitemapApiService = inject(SitemapApiService);

    public sitemap$ = this.sitemapApiService.getSitemap();

    ngOnInit(): void {
        this.collapsed = this.localstorageService.getBooleanKey(
            this.COLLAPSED_STORAGE_KEY
        );
    }

    toggle() {
        this.collapsed = !this.collapsed;
        this.localstorageService.setBooleanKey(
            this.COLLAPSED_STORAGE_KEY,
            this.collapsed
        );
    }

    open(content: any) {
        this.offcanvasService.open(content, { panelClass: 'offcanvas-sm' });
    }

    closeMobileNav() {
        this.offcanvasService.dismiss();
    }
}
