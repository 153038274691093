import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import {
    NgbModal,
    NgbModalOptions,
    NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { CookiePolicyModalComponent } from './cookie-policy-modal/cookie-policy-modal.component';

@Component({
    selector: 'cp-cookie-policy',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    templateUrl: './cookie-policy.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyComponent {
    private modalService = inject(NgbModal);

    @Input() isHidden = false;

    activeModal!: NgbModalRef;

    onOpenCookiePolicy(): void {
        const MODAL_CONFIG: NgbModalOptions = {
            size: 'xl',
            backdropClass: 'modal-backdrop--ngb',
            windowClass: 'modal-window--ngb',
            centered: true,
            animation: false,
            modalDialogClass: 'modal-dialog-message',
        };

        this.activeModal = this.modalService.open(
            CookiePolicyModalComponent,
            MODAL_CONFIG
        );
    }
}
