import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Article } from '@wdx/portal/api-models';
import {
    ERROR_STATUS,
    INITIAL_STATUS,
    LOADING_STATUS,
    SUCCESS_STATUS,
} from '../state.constants';
import { StateStatus } from '../state.model';
import { ArticlesActions } from './articles.actions';

export interface ListingState extends StateStatus, EntityState<Article> {}

export interface ArticlesState {
    listing: ListingState;
    article: ListingState;
}

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>();

const { selectAll } = adapter.getSelectors();

export const selectAllTeam = selectAll;

export const initialState: ArticlesState = {
    listing: adapter.getInitialState({
        ...INITIAL_STATUS,
    }),
    article: adapter.getInitialState({
        ...INITIAL_STATUS,
    }),
};

export const articlesReducer = createReducer(
    initialState,

    on(ArticlesActions.getListing, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            loading: !state.listing.loaded,
        },
    })),

    on(ArticlesActions.getListingFromAPI, (state, props) => ({
        ...state,
        listing: adapter.addMany(props.response, {
            ...state.listing,
            ...SUCCESS_STATUS,
        }),
    })),

    on(ArticlesActions.getListingError, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            ...ERROR_STATUS,
        },
    })),

    on(ArticlesActions.getArticle, (state, props) => ({
        ...state,
        article: {
            ...state.article,
            ...LOADING_STATUS,
            loading: !(state.article.ids as string[]).includes(props.articleId),
        },
    })),

    on(ArticlesActions.getArticleFromAPI, (state, props) => ({
        ...state,
        article: adapter.addOne(props.response, {
            ...state.article,
            ...SUCCESS_STATUS,
        }),
    })),

    on(ArticlesActions.getArticleError, (state) => ({
        ...state,
        article: {
            ...state.article,
            ...ERROR_STATUS,
        },
    }))
);
