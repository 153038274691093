import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@wdx/shared/infrastructure/state';
import { PortfolioHoldingsState } from './portfolio-holdings.reducer';

export const selectState =
    createFeatureSelector<PortfolioHoldingsState>('portfolio-holdings');

export const selectCurrentPortfolioId = createSelector(
    selectRouteParams,
    (state) => state?.['portfolioId']
);

export const selectCurrentPortfolio = createSelector(
    selectState,
    selectCurrentPortfolioId,
    (state, portfolioId) => state[portfolioId]
);

export const selectCurrentAssetClass = createSelector(
    selectCurrentPortfolio,
    (state) => state?.currentAssetClass
);

export const selectCurrentResults = createSelector(
    selectCurrentPortfolio,
    (state) =>
        state?.assetClasses && state.assetClasses[state.currentAssetClass]
);

export const selectAssetClassNames = createSelector(
    selectCurrentPortfolio,
    (state) => (state?.assetClasses ? Object.keys(state.assetClasses) : [])
);

export const selectCurrentIsLoading = createSelector(
    selectCurrentPortfolio,
    (state) => state?.loading
);

export const selectCurrentIsLoaded = createSelector(
    selectCurrentPortfolio,
    (state) => state?.loaded
);

export const selectCurrentSortDescending = createSelector(
    selectCurrentPortfolio,
    (state) => state?.currentSortDescending
);

export const selectCurrentSortField = createSelector(
    selectCurrentPortfolio,
    (state) => state?.currentSortField
);
