import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Breadcrumb } from '@wdx/shared/utils';
import { BreadcrumbService } from '../../../services/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'cp-breadcrumbs',
    standalone: true,
    imports: [CommonModule, RouterModule, PushPipe],
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    public breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

    constructor(
        private router: Router,
        private breadcrumbService: BreadcrumbService
    ) {}

    onNavigation(breadcrumb: Breadcrumb) {
        this.router.navigateByUrl(breadcrumb?.path as string);
    }
}
