import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private auth0Service: AuthService,
        private configService: ConfigService
    ) {}

    logout() {
        this.auth0Service.logout({
            clientId: this.configService.getConfig().AuthenticationClientId,
            logoutParams: { returnTo: `${window.location.origin}` },
        });
    }
}
