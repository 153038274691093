import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectQueryParams } from '@wdx/shared/infrastructure/state';
import { DocumentsState, adapter } from './documents.reducer';

export const selectState = createFeatureSelector<DocumentsState>('documents');

const { selectIds, selectEntities } = adapter.getSelectors();

export const selectDocumentsListingState = createSelector(
    selectState,
    (state) => state.listing
);

export const selectDocumentsLoadedPages = createSelector(
    selectDocumentsListingState,
    selectIds
);

export const selectDocumentsPages = createSelector(
    selectDocumentsListingState,
    selectEntities
);

export const selectDocumentsCurrentPageNumber = createSelector(
    selectQueryParams,
    (state) => state['page'] || 1
);

export const selectCurrentDocumentsPage = createSelector(
    selectDocumentsPages,
    selectDocumentsCurrentPageNumber,
    (pages, pageNumber) => pageNumber && pages[pageNumber]
);

export const selectDocumentsListingIsLoading = createSelector(
    selectDocumentsListingState,
    (state) => state?.loading
);

export const selectListingParamKey = createSelector(
    selectDocumentsListingState,
    (state) => state.paramKey
);

export const selectDocumentsListingUnreadCount = createSelector(
    selectDocumentsListingState,
    (state) => state?.unreadCount
);
