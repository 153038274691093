import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaviconService } from '@wdx/shared/utils';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';

@Component({
    templateUrl: './no-clients.component.html',
    styleUrls: ['./no-clients.component.scss'],
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoClientsComponent {
    faviconService = inject(FaviconService);
    authenticationService = inject(AuthenticationService);

    logout() {
        this.authenticationService.logout();
    }
}
