import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { DocumentApiService } from '../../libs/api-contract';
import { DocumentsActions } from './documents.actions';
import { selectDocumentsLoadedPages } from './documents.selectors';

@Injectable()
export class DocumentsEffects {
    getDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentsActions.getListing),
            withLatestFrom(
                this.store$.pipe(select(selectDocumentsLoadedPages)),
            ),
            switchMap(([action, loadedPagesIds]) => {
                if ((loadedPagesIds as number[]).includes(action.pageNumber)) {
                    return of(DocumentsActions.getListingFromStore());
                }
                return this.documentApiService
                    .getDocuments(action.pageNumber, action.paramKey)
                    .pipe(
                        map((response) =>
                            DocumentsActions.getListingFromAPI({
                                pageNumber: action.pageNumber,
                                response,
                                paramKey: action.paramKey,
                            }),
                        ),
                        catchError(() => EMPTY),
                    );
            }),
        ),
    );

    getListingFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(DocumentsActions.getListingFromStore)),
        { dispatch: false },
    );

    setParamKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentsActions.setParamKey),
            map((action) =>
                DocumentsActions.getListing({
                    pageNumber: 1,
                    paramKey: action.paramKey,
                }),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private documentApiService: DocumentApiService,
    ) {}
}
