<div class="d-flex justify-content-end position-relative">
    <wdx-icon-button
        class="border-0 position-absolute top-0 end-0 m-2"
        (wdxOnClick)="onClose()"
    >
        <wdx-icon icon="xmark"></wdx-icon>
    </wdx-icon-button>
    <div class="flex-grow-1 p-4">
        <div [innerHTML]="externalHtmlContent"></div>
    </div>
</div>
