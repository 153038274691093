import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { UserApiService } from '../../libs/api-contract';
import { TeamActions } from './team.actions';
import * as teamSelectors from './team.selectors';

@Injectable()
export class TeamEffects {
    getTeam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TeamActions.getListing),
            withLatestFrom(
                this.store$.pipe(select(teamSelectors.selectListingIsLoaded)),
            ),
            switchMap(([, loaded]) => {
                if (loaded) {
                    return of(TeamActions.getListingFromStore());
                }
                return this.userApiService.getTeam().pipe(
                    map((response) =>
                        TeamActions.getListingFromAPI({
                            response,
                        }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        ),
    );

    getListingFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(TeamActions.getListingFromStore)),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private userApiService: UserApiService,
    ) {}
}
